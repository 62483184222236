<template>
  <div class="px-5">
    <div class="text-lg flex items-center my-3 txt-grey-900">
      <span
        class="cursor-pointer fw-500 txt-grey-700 hover:underline"
        @click="handleBackSubOrgList"
      >{{ org.name }}</span>
      <img
        class="mx-2"
        src="../../assets/images/keyboard_arrow_right.png"
        alt="keyboard_arrow_right"
      />
      <span
        class="cursor-pointer fw-500 txt-grey-700 hover:underline"
        @click="backWS"
      >{{ $t("workspace_setting.lbl_list_workspace")[$orgModel] }} ({{ listWS.length }})</span>
      <img
        class="mx-2"
        src="../../assets/images/keyboard_arrow_right.png"
        alt="keyboard_arrow_right"
      />
      <span>{{ getWSName }}</span>
    </div>
    <div>
      <div class="bg-white rounded-3xl p-3">
        <div class="row pt-3">
          <p class="fs-20 fw-700 txt-grey-900 mb-4">{{$t('workspace_setting.lbl_staff')}}</p>
          <div class="d-flex mb-0">
            <div
              class="d-flex justify-content-center align-items-center p-2 cursor-pointer"
              :class="type == 'personnel' ? 'link' : ''"
              @click="changeType('personnel')"
            >
              <span
                class="robo-14-500"
                :class="type == 'personnel' ? 'txt-pri' : ''"
              >{{$t('workspace_setting.lbl_joined')}}</span>
            </div>
            <div
              class="d-flex justify-content-center align-items-center p-2 cursor-pointer"
              :class="type == 'sent' ? 'link' : ''"
              @click="changeType('sent')"
            >
              <span
                class="robo-14-500"
                :class="type == 'sent' ? 'txt-pri' : ''"
              >{{$t('workspace_setting.lbl_invited_list')}}</span>
            </div>
            <div
              class="d-flex justify-content-center align-items-center p-2 cursor-pointer"
              :class="type == 'approval' ? 'link' : ''"
              @click="changeType('approval')"
            >
              <span
                class="robo-14-500"
                :class="type == 'approval' ? 'txt-pri' : ''"
              >{{$t('workspace_setting.lbl_request_list')}} {{waiting_count ? '(' + waiting_count + ')' : ''}}</span>
            </div>
          </div>
        </div>

        <div>
          <div v-if="type === 'approval'">
            <DoctorWorkspaceClinic
              :wsId="wsId"
              :wsInfo="getWSInfo"
              isOrgPage
              @waitingCount="waitingCount"
            ></DoctorWorkspaceClinic>
          </div>
          <div v-if="type === 'sent'">
            <InvitedDoctors :wsId="wsId" :wsInfo="getWSInfo" isOrgPage />
          </div>
          <div v-if="type === 'personnel'">
            <ListStaff :wsId="wsId" :wsInfo="getWSInfo" isOrgPage />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import InvitedDoctors from '../../components/Clinic/InvitedDoctors.vue'
import ListStaff from '../../components/Clinic/ListStaff.vue'
import DoctorWorkspaceClinic from '../../components/Clinic/_DoctorWorkspaceClinic.vue'
import appUtils from '../../utils/appUtils'

export default {
  name: 'OrganizationWsStaffManage',
  components: { DoctorWorkspaceClinic, InvitedDoctors, ListStaff },
  data () {
    return {
      org: this.$globalOrg || '',
      type: 'personnel',
      waiting_count: 0,
      orgId: 0,
      wsId: Number(this.$route.params.id) || null,
      doctorsInWs: {},
      appUtils
    }
  },
  async created () {
    this.wsId = Number(this.$route.params.id)
    await this.getStaffInWs()

    this.orgId = this.$globalOrg?.id
    this.getOrg(this.orgId)
    this.getWorkSpacesAllV2(this.orgId)
  },
  mounted () {
    this.type = this.$route.query.type
      ? this.$route.query.type.toLowerCase()
      : 'personnel'
  },
  watch: {
    doctorsInWs (data) {
      console.log(data)
    }
  },
  computed: {
    ...mapState({
      listWS: state => state.listWS
    }),
    getWSName () {
      const data = this.listWS.find(item => item.id === this.wsId)
      return data?.name || ''
    },
    getWSInfo () {
      const data = this.listWS.find(item => item.id === this.wsId)
      return data
    }
  },
  methods: {
    async getWorkSpacesAllV2 (id) {
      if (!id) return
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getWorkSpacesAllV2(id)
      this.workspaces = res.data?.reverse()
      this.$store.commit('setListWS', { listWS: res.data })
      this.totalItems = res.data?.length
    },
    changeType (str_type) {
      let self = this
      self.type = str_type
      self.$router
        .push({
          path: `/organization/workspace/${self.wsId}`,
          query: { type: self.type }
        })
        .catch(() => {})
    },
    waitingCount (n) {
      this.waiting_count = n
    },
    backOrg () {
      this.$router.push({
        path: appUtils.getOrgId() ? '/organization/management' : '/'
      })
    },
    handleBackSubOrgList () {
      this.$router.push({
        name: 'OrganizationManagement'
      })
    },
    backWS () {
      this.$router.push({
        path: `/organization/workspace`
      })
    },
    async getOrg (id) {
      if (!id) return
      const res = await this.$rf.getRequest('DoctorRequest').getOrg(id)
      this.org = res.data
    },
    async getStaffInWs () {
      // const self = this
      // try {
      //   const params = {
      //     limit: 15
      //   }
      //   await self.$rf
      //     .getRequest('DoctorRequest')
      //     .getDoctorWorkspace(self.wsId, params)
      //     .then(res => {
      //       if (!res.data?.data) {
      //         self.doctorsInWs = null
      //         return
      //       }
      //       self.doctorsInWs = res.data
      //     })
      //     .finally(() => {})
      // } catch (error) {
      //   console.log(error)
      // }
    }
  }
}
</script>

<style>
</style>
